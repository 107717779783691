import * as React from 'react';
import { TableHead, TableContainer, TableCell, TableBody, Table, TableRow, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Displays table with list of users
export default function UserListTable(props) {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('study_id')}</TableCell>
                        <TableCell align="center">{t('external_id')}</TableCell>
                        <TableCell align="center">{t('connected_loyalty_cards')}</TableCell>
                        <TableCell align="center">{t('last_purchase_timestamp')}</TableCell>
                        <TableCell align="center">{t('last_successful_data_update')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* first check if users array is empty before calling .map function */}
                    {props.users && props.users.length
                        ? props.users.map((user) => (
                              <TableRow key={user.external_id} style={{ cursor: 'pointer' }}>
                                  <TableCell align="center">{user.study_id}</TableCell>
                                  <TableCell align="center">{user.external_id}</TableCell>
                                  <TableCell align="center">{user.connected_loyalty_card}</TableCell>
                                  <TableCell align="center">
                                      {user.last_purchase_timestamp
                                          ? new Date(user.last_purchase_timestamp * 1000).toLocaleDateString('de-DE')
                                          : t('no_shopping_data')}
                                  </TableCell>
                                  <TableCell align="center">
                                      {user.last_purchase_timestamp
                                          ? new Date(user.last_purchase_timestamp * 1000).toLocaleDateString('de-DE')
                                          : t('-')}
                                  </TableCell>
                              </TableRow>
                          ))
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

UserListTable.propTypes = {
    users: PropTypes.array.isRequired,
};
