import React, { useState } from 'react';
import { ThemeProvider, Divider, Toolbar, IconButton, List, Typography } from '@mui/material';
import colourTheme from '../../styles/ColourTheme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import { DieticianMainListItems } from '../../pages/DieticianDashboard/DieticianItems';
import AppBar from '../AppBar/AppBar';
import Drawer from '../AppBar/Drawer';
import LanguageSelect from '../Language/LanguageMenu';
import { useAuth } from '../../services/useAuth';
import { Title } from '../../utils/commonComponents';
import { getData } from '../../services/dietician';

function DieticianNavigation({ title }) {
    let navigate = useNavigate();
    let auth = useAuth();

    const { data: dietician } = useSWR('/dietician/profile', (url) => getData(url));

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <AppBar position="absolute" open={open}>
                    <Toolbar sx={{ pr: '24px' }}>
                        {/* keep right padding when drawer closed */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Title sx={{ flexGrow: 1 }} title={title} />
                        <LanguageSelect />
                        {dietician && (
                            <Typography sx={{ ml: 3, mr: 2 }}>
                                {dietician.firstName + ' ' + dietician.lastName}
                            </Typography>
                        )}
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                auth.logout();
                                navigate('/');
                            }}
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List>
                        <DieticianMainListItems />
                    </List>
                </Drawer>
            </ThemeProvider>
        </>
    );
}

DieticianNavigation.propTypes = {
    title: PropTypes.string.isRequired,
};

export default DieticianNavigation;
