import * as React from "react";
import { Container, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { colourTheme } from "../styles/ColourTheme";
import { useTranslation } from "react-i18next";
import LanguageHeader from '../components/Language/LanguageHeader';
import { centeredBox } from "../styles/ComponentStylings";



export default function UserRegistrationSuccess() {
    const { t } = useTranslation();

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <LanguageHeader />
                <Container component="main" maxWidth="s">
                    <Box sx={centeredBox}>
                        <Typography component="h3" variant="h6" align="center">
                            {t('user_registration_success')}
                        </Typography>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
