import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import two_fa from '../../assets/images/2_fa.png';

class Deactivate2fa extends Component {
    render() {
        const { t } = this.props;

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography align="center" sx={{ mt: 2 }}>
                    <Trans>
                        {t('deactivate_2fa')}
                        <a href="https://account.migros.ch/profile/security" target="_blank" rel="noreferrer">
                            {' '}
                        </a>
                    </Trans>
                </Typography>
                <Typography align="center">
                    <img
                        src={two_fa}
                        alt="two-factor-authentication"
                        width="80%"
                        style={{
                            overflow: 'hidden',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderRadius: 25,
                            marginRight: 10,
                        }}
                    />
                </Typography>
            </Box>
        );
    }
}

Deactivate2fa.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation()(Deactivate2fa);
