import { configData } from '../config.js';

export function checkStudyId(url, studyId = null) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Study-Id': studyId,
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then((response) => response.status)
        .catch((e) => {
            console.log(e);
            return 500;
        });
}

export function sendRegistrationMin(credentials, survey) {
    for (var key in credentials) {
        if (Object.prototype.hasOwnProperty.call(credentials, key)) {
            var value = credentials[key];
            if (value === '') {
                delete credentials[key];
            }
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...credentials, ...survey }),
    };

    return fetch(`${configData.API_URL}/user/register-minimal`, requestOptions)
        .then((res) => res.status)
        .catch((e) => {
            console.log(e);
            return 500;
        });
}
