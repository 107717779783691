import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

import { DashboardNavItem } from '../../utils/commonComponents';

// Menu Items for Dietician dashboard on sidebar
export const DieticianMainListItems = () => {
    const { t } = useTranslation();
    return (
        <>
            <DashboardNavItem link="/dietician/dashboard" icon={<DashboardIcon />} text={t('overview')} />
            <DashboardNavItem link="/dietician/participants" icon={<PeopleIcon />} text={t('participants')} />
            <DashboardNavItem link="/dietician/profile" icon={<PersonIcon />} text={t('profile')} />
            <DashboardNavItem link="/dietician/settings" icon={<SettingsIcon />} text={t('settings')} />
        </>
    );
};
