import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { checkStudyId } from '../../services/user.js';
import SurveyPage from '../../pages/SurveyPage.js';
import { SurveyButtonGroup } from '../../utils/commonComponents.js';
import { SurveyTextField } from '../../utils/commonComponents.js';
import PropTypes from 'prop-types';

class StudyId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    validateStudyId = async () => {
        const studyId = this.props.survey.studyId;
        if (/^(BrainFood2_[0-9][0-9][0-9])$/i.test(studyId)) {
            try {
                const status_code = await checkStudyId('/user/check-study-id', studyId);
                return status_code;
            } catch (e) {
                return 500;
            }
        }
        return 400;
    };

    continue = async (e) => {
        e.preventDefault();
        let studyIdStatusCode = await this.validateStudyId(e);

        if (studyIdStatusCode === 200) {
            this.props.nextStep(2);
        } else if (studyIdStatusCode === 400) {
            alert(i18next.t('error_invalid_study_id'));
        } else if (studyIdStatusCode === 409) {
            alert(i18next.t('error_used_study_id'));
        } else {
            alert(i18next.t('error_connecting_server'));
        }
    };

    render() {
        const { survey, t } = this.props;

        return (
            <SurveyPage
                title={t('enter_study_id')}
                content={[
                    // <form
                    //     key="form"
                    //     onSubmit={(e) => {
                    //         e.preventDefault();
                    //     }}
                    // >
                    <SurveyTextField
                        key="studyId"
                        value={survey.studyId}
                        id="studyId"
                        label={t('form_label_study_id')}
                        error={this.state.studyIdError}
                        helperText={this.state.studyIdError && t('error_invalid_study_id')}
                        onChange={(e) => this.props.handleChange('studyId', e.target.value)}
                        autoFocus={true}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.continue(e);
                            }
                        }}
                    />,
                    <Grid key="button" container justifyContent="flex-end">
                        <SurveyButtonGroup
                            back={() => {
                                this.props.prevStep();
                            }}
                            next={this.continue}
                        />
                    </Grid>,
                    // </form>,
                ]}
            />
        );
    }
}

StudyId.propTypes = {
    survey: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    t: PropTypes.func.isRequired,
};

export default withTranslation()(StudyId);
