import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

export function FeatureNotImplementedAlert(props) {
    const { t } = useTranslation();
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="warning" onClose={props.onClose}>
                <AlertTitle>Ohoh!</AlertTitle>
                {t('alert_featurenotimplemented')}
            </Alert>
        </Dialog>
    );
}

export function ErrorAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="error" role="button" onClose={props.onClose}>
                <AlertTitle>{props.title}</AlertTitle>
                {props.text}
            </Alert>
        </Dialog>
    );
}

export function WarningAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="warning" color="warning" role="button" onClose={props.onClose}>
                {props.text}
                {props.content}
            </Alert>
        </Dialog>
    );
}

export function InfoAlert(props) {
    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Alert severity="info" color="info" role="button" onClose={props.onClose}>
                {props.text}
                {props.refresh && (
                    <Button
                        style={{ color: 'rgba(0, 0, 255, 1)', backgroundColor: 'rgba(0, 0, 0, 0)' }}
                        onClick={() => props.reloadPage()}
                    >
                        {t('refresh')}
                    </Button>
                )}
            </Alert>
        </Dialog>
    );
}

FeatureNotImplementedAlert.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

ErrorAlert.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
};

WarningAlert.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    text: PropTypes.string,
    content: PropTypes.string,
};

InfoAlert.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    text: PropTypes.string,
    refresh: PropTypes.bool,
    reloadPage: PropTypes.func,
};
