import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';


export default function CircularLoading() {
    return (
        <Box
            sx={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <CircularProgress color='primary' />
        </Box>
    )
}