import React from 'react';
import PropTypes from 'prop-types';

import Error404Page from './pages/Error404Page';
import SurveyFlow from './components/Survey/SurveyFlow';
import SignIn from './components/SignIn/SignIn';
import ForgotPassword from './components/SignIn/ForgotPassword';
import { useAuth } from './services/useAuth';
import { Navigate } from 'react-router-dom';
import DieticianDashboard from './pages/DieticianDashboard/DieticianDashboard';
import DieticianProfile from './pages/DieticianDashboard/DieticianProfile';
import Participants from './pages/DieticianDashboard/Participants';
import Settings from './pages/DieticianDashboard/Settings';
import VerifyEmailFirstPage from './pages/VerifyEmailFirstPage';
import AccountVerifiedPage from './pages/AccountVerifiedPage';

// checks if user is logged in and if so redirects to desired page
const ProtectedRoute = ({ children }) => {
    const auth = useAuth();
    if (!auth.user) return <Navigate to="error" replace />;
    return children;
};

const routes = [
    {
        path: 'dietician',
        children: [
            {
                path: 'dashboard',
                element: (
                    <ProtectedRoute>
                        <DieticianDashboard />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'profile',
                element: (
                    <ProtectedRoute>
                        <DieticianProfile />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'participants',
                element: (
                    <ProtectedRoute>
                        <Participants />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'settings',
                element: (
                    <ProtectedRoute>
                        <Settings />
                    </ProtectedRoute>
                ),
            },
            { path: '*', element: <Error404Page /> },
        ],
    },
    {
        path: '/',
        children: [
            { path: '/', element: <SurveyFlow /> },
            { path: '/signin', element: <SignIn /> },
            { path: '/reset-password', element: <ForgotPassword /> },
            { path: '/email-needs-verification', element: <VerifyEmailFirstPage /> },
            { path: '/account-verified', element: <AccountVerifiedPage /> },
            { path: '*', element: <Error404Page /> },
        ],
    },
];

ProtectedRoute.propTypes = {
    children: PropTypes.node,
};

export default routes;
