import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Dialog, Button, Paper, List, ListItem } from '@mui/material';
import PropTypes from 'prop-types';

export default function TermsAndConditions(props) {
    const { t } = useTranslation();

    return (
        <Dialog open={props.open} onClose={() => {}}>
            <Paper variant="outlined" elevation={2}>
                <Grid sx={{ p: 1 }}>
                    <Grid item>
                        <Typography variant="h6" sx={{ textAlign: 'center', p: 1 }}>
                            {t('termsandconditions_title')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item sx={{ textAlign: 'center', pt: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {t('tandc_subtitle1')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_projecttitle')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_projectconductedby')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_projectmanager')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_projectcontact')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text1')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle2')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text2')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle3')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text3')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle4')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text4')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle5')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text5')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle6')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text6')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_subtitle7')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            {t('tandc_text7')}
                        </Typography>
                    </Grid>
                    <Divider sx={{ m: 1 }} />
                    <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('tandc_declofconsent')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ mt: 1, textAlign: 'center' }}>
                            {t('tandc_declofconsentperson')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <List>
                            <ListItem>• {t('tandc_cons1')}</ListItem>
                            <ListItem>• {t('tandc_cons2')}</ListItem>
                            <ListItem>• {t('tandc_cons3')}</ListItem>
                            <ListItem>• {t('tandc_cons4')}</ListItem>
                            <ListItem>• {t('tandc_cons5')}</ListItem>
                            <ListItem>• {t('tandc_cons6')}</ListItem>
                            <ListItem>• {t('tandc_cons7')}</ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" sx={{ mt: 2, mb: 4 }}>
                    <Button onClick={props.onClose} variant="contained">
                        {t('close')}
                    </Button>
                </Grid>
            </Paper>
        </Dialog>
    );
}

TermsAndConditions.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
