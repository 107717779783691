import React from 'react';

import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';
import validateAuthFields from '../../utils/formValidator';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { ErrorAlert } from '../Alert/Alerts';
import { InputAdornment } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';

class DieticianRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameError: false,
            lastNameError: false,
            dieticianEmailError: false,
            dieticianPasswordError: false,
            passwordConfirmError: false,
            openErrorConnectionToServer: false,
            showPassword: false,
            showPasswordConfirm: false,
        };
    }

    validateForm = async () => {
        let firstNameError = false;
        let lastNameError = false;
        let emailError = false;
        let passwordError = false;
        let passwordConfirmError = false;
        this.props.data.dieticianInfo.firstName.length === 0 ? (firstNameError = true) : (firstNameError = false);
        this.props.data.dieticianInfo.lastName.length === 0 ? (lastNameError = true) : (lastNameError = false);
        try {
            emailError = this.props.data.credentials.email.length === 0;
        } catch (_) {
            this.setState({ openErrorConnectionToServer: true });
        }
        passwordError = !validateAuthFields('password', this.props.data.credentials.password);
        passwordConfirmError = !(this.props.data.credentials.password === this.props.data.credentials.passwordConfirm);
        this.setState({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            dieticianEmailError: emailError,
            dieticianPasswordError: passwordError,
            passwordConfirmError: passwordConfirmError,
        });
        if (firstNameError || lastNameError || emailError || passwordError || passwordConfirmError) return;
        this.props.submitData();
    };

    render() {
        const { data, handleAuthChange, handleDieticianInfoChange, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_dietician')}
                content={[
                    <SurveyTextField
                        key="firstName"
                        value={data.dieticianInfo.firstName}
                        id="dieticianFirstName"
                        required={true}
                        label={t('form_label_first_name')}
                        placeholder={t('form_label_first_name')}
                        error={this.state.firstNameError}
                        onChange={(e) => handleDieticianInfoChange('firstName', e.target.value)}
                        helperText={this.state.firstNameError && t('form_error_name')}
                    />,
                    <SurveyTextField
                        key="lastName"
                        value={data.dieticianInfo.lastName}
                        id="dieticianLastName"
                        label={t('form_label_last_name')}
                        placeholder={t('form_label_last_name')}
                        error={this.state.lastNameError}
                        onChange={(e) => handleDieticianInfoChange('lastName', e.target.value)}
                        helperText={this.state.lastNameError && t('form_error_name')}
                    />,
                    <SurveyTextField
                        key="email"
                        value={data.credentials.email}
                        id="dieticianEmail"
                        label="Email"
                        placeholder="E-Mail"
                        error={this.state.dieticianEmailError}
                        onChange={(e) => handleAuthChange({ email: e.target.value })}
                        helperText={this.state.dieticianEmailError && t('form_error_email')}
                    />,
                    <SurveyTextField
                        key="password"
                        value={data.credentials.password}
                        id="dieticianPassword"
                        label={t('form_password')}
                        placeholder={t('form_password')}
                        error={this.state.dieticianPasswordError}
                        onChange={(e) => handleAuthChange({ password: e.target.value })}
                        helperText={this.state.dieticianPasswordError && t('form_error_password')}
                        type={this.state.showPassword ? 'text' : 'password'}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                    >
                                        {this.state.showPassword ? (
                                            <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                        ) : (
                                            <VisibilityIcon style={{ fontSize: 'small' }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />,
                    <SurveyTextField
                        key="passwordConfirm"
                        value={data.credentials.passwordConfirm}
                        id="passwordConfirm"
                        label={t('form_confirm_password')}
                        placeholder={t('form_confirm_password')}
                        autoComplete="new-password"
                        onChange={(e) => handleAuthChange({ passwordConfirm: e.target.value })}
                        error={this.state.passwordConfirmError}
                        helperText={this.state.passwordConfirmError && t('form_error_password_match')}
                        type={this.state.showPasswordConfirm ? 'text' : 'password'}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm })
                                        }
                                    >
                                        {this.state.showPasswordConfirm ? (
                                            <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                        ) : (
                                            <VisibilityIcon style={{ fontSize: 'small' }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />,
                    <SurveyButtonGroup
                        key="button"
                        back={() => {
                            this.props.prevStep();
                        }}
                        next={this.validateForm}
                    />,
                    <ErrorAlert
                        key="error"
                        open={this.state.openErrorConnectionToServer}
                        onClose={() => this.setState({ openErrorConnectionToServer: false })}
                        text={this.props.t('error_connecting_server')}
                    />,
                ]}
            />
        );
    }
}

DieticianRegister.propTypes = {
    data: PropTypes.object.isRequired,
    handleAuthChange: PropTypes.func.isRequired,
    handleDieticianInfoChange: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation()(DieticianRegister);
