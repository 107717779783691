import { createTheme } from '@mui/material/styles';


export const colourTheme = createTheme({
    palette: {
        primary: {
            main: '#0d47a1',
            light: '#5472d3',
            dark: '#002171'
        },
        secondary: {
            main: '#FFFAFA'
        },
        info: {
            main: '#5e92f3'
        },
        alert: {
            main: '#EB4D42'
        }
    }
});

export default colourTheme;

export const darkColourTheme = createTheme({
    palette: {
        primary: {
            main: '#002171',
        },
        secondary: {
            main: '#FFFAFA'
        },
        alert: {
            main: '#EB4D42'
        }
    }
});
