import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Typography, Button, ButtonGroup, Grid, TextField } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/Error';

import { linkStyling } from '../styles/ComponentStylings';

export function Title(props) {
    return (
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={props.sx || { flexGrow: 1, pl: 2.5, pb: 2 }}>
            {props.title}
        </Typography>
    );
}

// items for dashboard menu
export function DashboardNavItem(props) {
    const { t } = useTranslation();

    return (
        <Link to={props.link} style={linkStyling} color="black">
            <ListItem button>
                <ListItemIcon>{props.icon}</ListItemIcon>
                <ListItemText primary={t(props.text)} />
            </ListItem>
        </Link>
    );
}

export function SurveyTextField(props) {
    const { inputProps, ...otherProps } = props;

    return (
        <TextField
            margin="normal"
            value={props.value}
            required
            fullWidth
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            name={props.id}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            type={props.type}
            onChange={props.onChange}
            error={props.error}
            helperText={props.helperText}
            inputProps={{ ...inputProps }} // Spread both inputProps and inputProps
            {...otherProps} // Spread remaining props
        />
    );
}

// back and next button for survey pages
export function SurveyButtonGroup(props) {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center">
            <ButtonGroup
                fullwidth="true"
                variant="contained"
                color="inherit"
                size="large"
                aria-label="large button group"
                sx={{ mt: 3, mb: 2 }}
            >
                <Button onClick={props.back}>{t('button_back')}</Button>
                <Button onClick={props.next}>{t('button_next')}</Button>
            </ButtonGroup>
        </Grid>
    );
}

export function ProfileInfo(props) {
    return (
        <TextField
            fullWidth
            label={props.label}
            margin="dense"
            defaultValue={props.value}
            variant="filled"
            multiline
            InputProps={{ readOnly: true }}
        />
    );
}

export const ErrorMessage = ({ message }) => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

                backgroundColor: '#c94242',
                color: 'white',
                padding: '10px',
                borderRadius: '5px',
                margin: '10px 0',
            }}
        >
            <ErrorIcon style={{ color: 'black', marginRight: '5px' }} />
            <Trans>
                {t(message)}
                <a href="https://account.migros.ch/profile/security" target="_blank" rel="noreferrer">
                    {' '}
                </a>
            </Trans>
        </div>
    );
};

Title.propTypes = {
    title: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

DashboardNavItem.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
};

SurveyTextField.propTypes = {
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    inputProps: PropTypes.object,
};

SurveyButtonGroup.propTypes = {
    back: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
};

ProfileInfo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
};
