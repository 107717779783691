import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

import { ThemeProvider } from '@material-ui/core';
import colourTheme from './styles/ColourTheme';
// import brainfood from './assets/brainfood.png';
import './App.css';
// import { useTranslation } from 'react-i18next';
import { ProvideAuth } from './services/useAuth';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
    // const { t } = useTranslation();
    const content = useRoutes(routes);
    return (
        <ErrorBoundary>
            <ProvideAuth>
                <ThemeProvider theme={colourTheme}>{content}</ThemeProvider>
            </ProvideAuth>
        </ErrorBoundary>
    );
}

export default App;
