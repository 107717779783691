import { configData } from '../config.js';
import { getPostRequestOptions, getRequestOptions, handleResponse } from './utils.js';

export function getData(url, userId = null, startTimestamp = null, endTimestamp = null, category = null) {
    const requestOptions = getRequestOptions(userId, startTimestamp, endTimestamp, category);
    return fetch(`${configData.API_URL}${url}`, requestOptions).then(handleResponse);
}

export function registerDietician(data) {
    const signUpData = {
        email: data.credentials.email,
        password: data.credentials.password,
        firstName: data.dieticianInfo.firstName,
        lastName: data.dieticianInfo.lastName,
    };
    const requestOptions = getPostRequestOptions(signUpData);

    return fetch(`${configData.API_URL}/dietician/register`, requestOptions)
        .then(async (res) => {
            return res.status;
        })
        .catch(() => {
            return 500;
        });
}

export const sendResetPasswordMail = async (email) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        };

        return await fetch(`${configData.API_URL}/user/pwreset`, requestOptions).then((r) =>
            r.text().then((data) => ({ status: r.status, body: data }))
        );
    } catch (error) {
        return null;
    }
};

export const sendChangePasswordRequest = async (token, password) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, password: password }),
    };
    return await fetch(`${configData.API_URL}/user/update-password`, requestOptions)
        .then((r) => r.status)
        .catch(() => {
            return null;
        });
};
