import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { colourTheme } from '../styles/ColourTheme';
import { useTranslation } from 'react-i18next';
import LanguageHeader from '../components/Language/LanguageHeader';
import { centeredBox, mainButtonStyle } from '../styles/ComponentStylings';

// for dieticians only
export default function AccountVerifiedPage() {
    const { t } = useTranslation();

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <LanguageHeader />
                <Container component="main" maxWidth="s">
                    <Box sx={centeredBox}>
                        <Typography component="h3" variant="h6" align="center">
                            {t('verification_completed')}
                        </Typography>
                    
                    <Box sx={{ marginTop: 8, '& button': { m: 1 }, display: 'flex', flexDirection: 'column' }}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button color="primary" variant="contained" sx={mainButtonStyle}>
                            {t('button_home')}
                        </Button>
                    </Link>
                    </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
