import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ConnectLoyaltyCards from '../../pages/ConnectLoyaltyCards';
import Home from './Home';
import StudyId from './StudyId';
import UserRegistrationSuccess from '../../pages/UserRegistrationSuccess';
import DieticianRegistrationSuccess from '../../pages/DieticianRegistrationSuccess';
import DieticianRegister from './DieticianRegister';
import { sendRegistrationMin } from '../../services/user.js';
import { registerDietician } from '../../services/dietician.js';
// import SurveyInfoPage from '../../pages/SurveyInfoPage';
import { scraperService } from '../../services/scraperService';
import PropTypes from 'prop-types';

export class SurveyFlow extends Component {
    constructor(props) {
        super(props);
        // delete user from localstorage if token is stored
        localStorage.removeItem('user');
        let step = 0;
        let connectedLoyaltyCard = '';
        this.state = {
            step: step,
            openMigrosDialog: false,
            openCoopDialog: false,
            credentials: {
                email: '',
                password: '',
                passwordConfirm: '',
                migrosEmail: '',
                migrosPassword: '',
                coopEmail: '',
                coopPassword: '',
            },
            dieticianInfo: {
                firstName: '',
                lastName: '',
            },
            survey: {
                userType: '',
                studyId: '',
                connectedLoyaltyCard: connectedLoyaltyCard,
            },
        };
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    };

    handleSurveyChange = (key, value) => {
        // create a copy of state and change the value of key
        const updateState = { ...this.state.survey, [key]: value };
        this.setState({ survey: updateState });
    };

    handleDieticianInfoChange = (key, value) => {
        // create a copy of state and change the value of key
        this.setState((prevState) => ({ ...prevState, dieticianInfo: { ...prevState.dieticianInfo, [key]: value } }));
    };

    handleAuthChange = (keysValues) => {
        const updateState = { ...this.state.credentials, ...keysValues };
        let connectedLoyaltyCard = '';
        if (updateState.migrosEmail && !updateState.coopEmail) {
            connectedLoyaltyCard = 'migros';
        } else if (!updateState.migrosEmail && updateState.coopEmail) {
            connectedLoyaltyCard = 'coop';
        } else if (updateState.migrosEmail && updateState.coopEmail) {
            connectedLoyaltyCard = 'both';
        }

        this.setState({
            credentials: updateState,
            survey: { ...this.state.survey, connectedLoyaltyCard: connectedLoyaltyCard },
        });
    };

    submitRegistrationMin = async () => {
        await sendRegistrationMin(this.state.credentials, this.state.survey).then((status) => {
            if (status === 201) {
                this.setState({ step: this.state.step + 1 });
            } else alert(this.props.t('fail_to_register_user'));
        });
    };

    // submit signup / survey data to backend
    submitDieticianRegistration = () => {
        const data = { ...this.state };

        // delete data that should not be sent to backend
        delete data.credentials['passwordConfirm'];

        registerDietician(data).then((status) => {
            if (status === 201) {
                this.setState({ step: this.state.step + 3 });
            } else if (status === 409) {
                alert(this.props.t('error_registered_dietician_email'));
            } else if (status === 400) {
                alert(this.props.t('error_invalid_dietician_email'));
            } else {
                alert(this.props.t('error_connecting_server'));
            }
        });
    };

    render() {
        const authValues = this.state.credentials;
        // Each step defines the survey page that should be displayed
        switch (this.state.step) {
            case 0:
                return (
                    <Home
                        nextStep={() => {
                            this.handleSurveyChange('userType', 'Participant');
                            this.setState({ step: this.state.step + 1 });
                        }}
                        nextStepAdmin={() => {
                            this.handleSurveyChange('userType', 'Dietician');
                            this.setState({ step: this.state.step + 2 });
                        }}
                    />
                );
            case 1:
                return (
                    <StudyId
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        nextStep={() => this.setState({ step: this.state.step + 2 })}
                        handleChange={this.handleSurveyChange}
                        survey={this.state.survey}
                    />
                );
            case 2:
                return (
                    <DieticianRegister
                        submitData={this.submitDieticianRegistration}
                        prevStep={() => this.setState({ step: this.state.step - 2 })}
                        handleAuthChange={this.handleAuthChange}
                        handleDieticianInfoChange={this.handleDieticianInfoChange}
                        data={this.state}
                    />
                );
            case 3:
                // this is only for HSG data collection method
                return (
                    <ConnectLoyaltyCards
                        nextStep={async () => {
                            await this.submitRegistrationMin(this.state.credentials);
                            scraperService.scraperRequest('POST', '/user/reset', {
                                studyId: this.state.survey.studyId,
                            });
                        }}
                        prevStep={() => {
                            this.setState({ step: this.state.step - 2 });
                        }}
                        handleChange={this.handleAuthChange}
                        authValues={authValues}
                    />
                );

            case 4:
                return <UserRegistrationSuccess />;
            case 5:
                return <DieticianRegistrationSuccess />;
            default:
                return <Home />;
        }
    }
}

SurveyFlow.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(SurveyFlow);
