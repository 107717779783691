// input validation for form fields
export default function validateAuthFields(fieldname, value) {
    let valid = false;

    if (value !== '') {
        switch (fieldname) {
            case 'email':
                {
                    // This regex accepts anystring@anystring.anystring
                    let re = /\S+@\S+\.\S+/;
                    valid = re.test(value);
                }
                break;
            case 'password': {
                let atLeastOneDigit = /^.*\d.*$/;
                let atLeastOneLowerCase = /^.*[a-z].*$/;
                let atLeastOneUpperCase = /^.*[A-Z].*$/;
                let atLeastOneSymbol = /^.*[^a-zA-Z\d\s].*$/;
                if (
                    value.length >= 8 &&
                    value.length <= 24 &&
                    value.match(atLeastOneDigit) &&
                    value.match(atLeastOneLowerCase) &&
                    value.match(atLeastOneUpperCase) &&
                    value.match(atLeastOneSymbol)
                ) {
                    valid = true;
                }
                break;
            }
            default:
                break;
        }
    }
    return valid;
}

// input validation for survey form fields
export function validateSurveyFields(fieldname, value) {
    let valid = false;

    if (value !== '' && value !== undefined && value !== null) {
        switch (fieldname) {
            case 'select':
                // for any select input that doesn't need more checks other than if something is selected
                valid = true;
                break;
            default:
                break;
        }
    }
    return valid;
}
