export const mainButtonStyle = {
    width: "250px",
    padding: "20px",
    textAlign: "center",
    position: "relative",
    margin: "4px 10px",
    boxShadow: "0px 0px 12px -2px rgba(0,0,0,0.5)",
    lineHeight: "1.25",
    fontSize: "24px",
    letterSpacing: ".08em",
    textTransform: "uppercase",
    overflow: "hidden",
    borderRadius: "8px",
    border: "2px solid white",
};

export const centeredBox = {
    position: 'absolute', left: '5%', right: '5%', top: '15%',
    //transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export const linkStyling = {
    textDecoration: 'none',
    color: 'black'
};

export const linkWithUnderline = {
    textDecoration: 'underline',
    color: 'black'
};

export const flexBox = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export const paperStyling = {
    p: 2,
    mb: 2,
    display: 'grid',
    flexDirection: 'column',
    height: 'auto',
}