import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import SurveyInfoPage from './SurveyInfoPage';
import { Button, Grid, Typography, Container } from '@mui/material';
import PropTypes from 'prop-types';

import { linkWithUnderline } from '../../styles/ComponentStylings';
// import hsg_logo from '../assets/images/hsg_logo.png';
import inselspital_logo from '../../assets/images/inselspital_logo.png';

export default function Home(props) {
    const { t } = useTranslation();

    return (
        <SurveyInfoPage
            title={t('welcome')}
            content={[
                <>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1 }}>
                        {/* <img src={hsg_logo} alt="Logo" width="50" height="50" style={{ marginRight: 20 }} /> */}
                        <img src={inselspital_logo} alt="Logo" width="50" height="50" />
                    </Typography>
                    <Typography component="h1" variant="body1" color="inherit" align="center" sx={{ pb: 1 }}>
                        <Trans i18nKey="brainfood_intro" />
                    </Typography>
                    <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                        <Button
                            sx={{ width: '255px' }}
                            variant="contained"
                            color="info"
                            size="large"
                            aria-label="large button group"
                            onClick={props.nextStep}
                        >
                            {t('signup')}
                        </Button>
                    </Grid>
                    <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                        <Button
                            sx={{ width: '255px' }}
                            variant="contained"
                            color="info"
                            size="large"
                            aria-label="large button group"
                            onClick={props.nextStepAdmin}
                        >
                            {t('admin_signup')}
                        </Button>
                    </Grid>
                    <Container maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={12}>
                                <Link to="/signin" style={linkWithUnderline}>
                                    {t('signin_text_dietician')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Container>
                </>,
            ]}
        />
    );
}

Home.propTypes = {
    nextStep: PropTypes.func.isRequired,
    nextStepAdmin: PropTypes.func.isRequired,
};
