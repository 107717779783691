import React from 'react';
import { ThemeProvider, Box, CssBaseline, Toolbar } from '@mui/material';
import DieticianNavigation from '../../components/Navigation/DieticianNavigation';
import colourTheme from '../../styles/ColourTheme';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Layout page for all dashboard subpages
class DieticianDashboardPage extends React.Component {
    render() {
        const { navTitle, content } = this.props;

        return (
            <ThemeProvider theme={colourTheme}>
                <CssBaseline />
                <Box sx={{ display: 'flex' }}>
                    <DieticianNavigation title={navTitle} />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[200],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        {content}
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}

DieticianDashboardPage.propTypes = {
    navTitle: PropTypes.string,
    content: PropTypes.element,
};

export default withTranslation()(DieticianDashboardPage);
