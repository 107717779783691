import React, { Component } from 'react';
import {
    TextField,
    Grid,
    Button,
    Box,
    Dialog,
    DialogContent,
    Divider,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { ErrorMessage } from '../utils/commonComponents';

import { SurveyButtonGroup } from '../utils/commonComponents';
import { ErrorAlert } from '../components/Alert/Alerts';
import SurveyInfoPage from '../components/Survey/SurveyInfoPage';
import migros_logo from '../assets/images/migros_logo.png';
import coop_logo from '../assets/images/coop_logo.png';
import Deactivate2fa from '../components/Survey/Deactivate2Fa';
import { scraperService } from '../services/scraperService';
import TermsAndConditions from '../utils/termsAndConditions';

class ConnectLoyaltyCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            migrosEmail: '',
            migrosPassword: '',
            coopEmail: '',
            coopPassword: '',
            scraperMessageMigros: '',
            scraperMessageCoop: '',
            validatingMigrosCredentials: false,
            validatingCoopCredentials: false,
            migrosCredentialsValidated: null,
            coopCredentialsValidated: null,
            openMigrosDialog: false,
            openCoopDialog: false,
            showMigrosPassword: false,
            showCoopPassword: false,
            termsAndConditionsAgreed: false,
            displayTerms: false,
            openWarningDialog: false,
            openConnectionWarning: false,
        };
    }

    validateMigrosCredentials = async () => {
        this.setState({ validatingMigrosCredentials: true });
        try {
            const headers = {
                migrosEmail: this.state.migrosEmail,
                migrosPassword: this.state.migrosPassword,
            };
            const { status, data } = await scraperService.scraperRequest(
                'GET',
                '/validate/migros-credentials',
                headers
            );
            if (status === 200) {
                this.setState({ migrosCredentialsValidated: true });
                this.props.handleChange({
                    migrosEmail: this.state.migrosEmail,
                    migrosPassword: this.state.migrosPassword,
                });
            } else {
                let message = 'something_went_wrong';
                if (data.includes('Invalid email')) message = 'invalid_migros_email';
                else if (data.includes('Invalid password')) message = 'invalid_migros_password';
                else if (data.includes('User did not connect cumulus')) message = 'cumulus_not_connected';
                else if (data.includes('User has two factor authentication enabled')) message = '2fa_active';
                this.setState({ migrosCredentialsValidated: false, scraperMessageMigros: message });
            }
        } catch (error) {
            this.setState({ migrosCredentialsValidated: false, scraperMessageMigros: 'something_went_wrong' });
        }
        this.setState({ validatingMigrosCredentials: false });
    };

    validateCoopCredentials = async () => {
        this.setState({ validatingCoopCredentials: true });
        try {
            const headers = {
                coopEmail: this.state.coopEmail,
                coopPassword: this.state.coopPassword,
            };
            const { status, data } = await scraperService.scraperRequest('GET', '/validate/coop-credentials', headers);
            if (status === 200) {
                this.setState({ coopCredentialsValidated: true });
                this.props.handleChange({
                    coopEmail: this.state.coopEmail,
                    coopPassword: this.state.coopPassword,
                });
            } else {
                let message = 'something_went_wrong';
                if (data.includes('Invalid credentials')) message = 'invalid_coop_credentials';
                this.setState({ coopCredentialsValidated: false, scraperMessageCoop: message });
            }
        } catch (error) {
            this.setState({ coopCredentialsValidated: false, scraperMessageCoop: 'something_went_wrong' });
        }
        this.setState({ validatingCoopCredentials: false });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        //Check whether participants connect at least one loyalty card
        let connectionValidated = false;
        if (this.state.migrosCredentialsValidated || this.state.coopCredentialsValidated) connectionValidated = true;
        if (!connectionValidated) {
            this.setState({ openConnectionWarning: true });
            return;
        }

        //Check whether participants agree to the T&C
        let checkbox = document.getElementById('checkboxTerms').checked;
        if (!checkbox) {
            this.setState({ openWarningDialog: true });
        } else this.setState({ termsAndConditionsAgreed: true });

        if (connectionValidated && checkbox) this.props.nextStep();
    };

    handleTermsClick = () => {
        this.setState({ displayTerms: !this.state.displayTerms });
    };

    render() {
        const { t } = this.props;

        return (
            <>
                <SurveyInfoPage
                    title={t('connect_loyalty_cards')}
                    content={[
                        <>
                            <Typography fontWeight={600} fontSize={18} align="center">
                                {t('click_on_logos')}
                            </Typography>
                            <Grid container justifyContent="space-around" spacing={2} sx={{ pt: 2, pb: 2 }}>
                                <Grid container direction="column" item xs={6} alignItems={'center'}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ openMigrosDialog: true });
                                        }}
                                    >
                                        <img
                                            src={migros_logo}
                                            alt="Logo"
                                            width="150"
                                            height="150"
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                                boxShadow: '0px 0px 5px 2px grey',
                                            }}
                                        />
                                    </Button>
                                    {/* <Box width={32} height={32}> */}
                                    {this.state.validatingMigrosCredentials ? (
                                        <CircularProgress size={32} />
                                    ) : this.state.migrosCredentialsValidated ? (
                                        <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                    ) : (
                                        this.state.migrosCredentialsValidated === false &&
                                        this.state.scraperMessageMigros && (
                                            <ErrorMessage message={this.state.scraperMessageMigros} />
                                        )
                                    )}
                                    {/* </Box> */}
                                </Grid>
                                <Grid container direction="column" item xs={6} alignItems={'center'}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ openCoopDialog: true });
                                        }}
                                    >
                                        <img
                                            src={coop_logo}
                                            alt="Logo"
                                            width="150"
                                            height="150"
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                                boxShadow: '0px 0px 5px 2px grey',
                                            }}
                                        />
                                    </Button>
                                    {this.state.validatingCoopCredentials ? (
                                        <>
                                            <Box width={32} height={32}>
                                                <CircularProgress size={32} />
                                            </Box>
                                            <Typography fontWeight={600} fontSize={18} align="center">
                                                {t('validating_coop')}
                                            </Typography>
                                        </>
                                    ) : this.state.coopCredentialsValidated ? (
                                        <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                    ) : (
                                        this.state.coopCredentialsValidated === false && (
                                            <ErrorMessage message={this.state.scraperMessageCoop} />
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        </>,
                    ]}
                />

                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                onClick={() => this.setState({ displayTerms: !this.state.displayTerms })}
                                variant="outlined"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                {t('terms_and_conditions')}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FormControlLabel
                                control={<Checkbox id="checkboxTerms" value="acceptTerms" color="primary" required />}
                                label={t('agree_tac')}
                            />
                        </div>
                    </Grid>
                </Grid>

                <TermsAndConditions
                    open={this.state.displayTerms}
                    onClose={() => this.setState({ displayTerms: !this.state.displayTerms })}
                />
                <ErrorAlert
                    open={this.state.openConnectionWarning}
                    onClose={() => {
                        this.setState({ openConnectionWarning: false });
                    }}
                    text={t('alert_no_loyalty_card_conencted')}
                />
                <ErrorAlert
                    open={!this.state.termsAndConditionsAgreed && this.state.openWarningDialog}
                    onClose={() => this.setState({ openWarningDialog: false })}
                    text={t('alert_tac_unagreed')}
                />
                <Dialog open={this.state.openMigrosDialog} onClose={() => this.setState({ openMigrosDialog: false })}>
                    <DialogContent
                        sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                    >
                        <img
                            src={migros_logo}
                            alt="Logo"
                            width="150"
                            height="150"
                            style={{
                                padding: 10,
                                marginBottom: 10,
                                boxShadow: '0px 0px 5px 2px grey',
                            }}
                        />
                        <Deactivate2fa />
                        <Divider sx={{ width: '100%', my: 2 }} />
                        <TextField
                            margin="normal"
                            required
                            name="Migros Email"
                            label={t('Migros Email')}
                            type="Email"
                            id="Migros Email"
                            autoComplete="current-password"
                            value={this.state.migrosEmail}
                            onChange={(e) => this.setState({ migrosEmail: e.target.value })}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Migros password"
                            label={t('Migros password')}
                            id="Migros password"
                            autoComplete="current-password"
                            value={this.state.migrosPassword}
                            onChange={(e) => this.setState({ migrosPassword: e.target.value })}
                            type={this.state.showMigrosPassword ? 'text' : 'password'}
                            InputProps={{
                                style: { width: '230px' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.setState({ showMigrosPassword: !this.state.showMigrosPassword })
                                            }
                                        >
                                            {this.state.showMigrosPassword ? (
                                                <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                            ) : (
                                                <VisibilityIcon style={{ fontSize: 'small' }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            onClick={() => {
                                this.setState({ openMigrosDialog: false });
                                this.validateMigrosCredentials();
                            }}
                        >
                            {t('connect_my_loyalty_card')}
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.openCoopDialog} onClose={() => this.setState({ openCoopDialog: false })}>
                    <DialogContent
                        sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                    >
                        <img
                            src={coop_logo}
                            alt="Logo"
                            width="150"
                            height="150"
                            style={{
                                padding: 10,
                                marginBottom: 10,
                                boxShadow: '0px 0px 5px 2px grey',
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Supercard Email"
                            label="Supercard Email"
                            type="Email"
                            id="Supercard Email"
                            autoComplete="current-password"
                            value={this.state.coopEmail}
                            onChange={(e) => this.setState({ coopEmail: e.target.value })}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Supercard password"
                            label={t('supercard_password')}
                            id="Supercard password"
                            autoComplete="current-password"
                            value={this.state.coopPassword}
                            onChange={(e) => this.setState({ coopPassword: e.target.value })}
                            type={this.state.showCoopPassword ? 'text' : 'password'}
                            InputProps={{
                                style: { width: '230px' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.setState({ showCoopPassword: !this.state.showCoopPassword })
                                            }
                                        >
                                            {this.state.showCoopPassword ? (
                                                <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                            ) : (
                                                <VisibilityIcon style={{ fontSize: 'small' }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            onClick={() => {
                                this.setState({ openCoopDialog: false });
                                this.validateCoopCredentials();
                            }}
                        >
                            {t('connect_my_loyalty_card')}
                        </Button>
                    </DialogContent>
                </Dialog>
                <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                    <SurveyButtonGroup back={this.props.prevStep} next={this.handleSubmit} />
                </Grid>
            </>
        );
    }
}

ConnectLoyaltyCards.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation()(ConnectLoyaltyCards);
