import { configData } from '../config.js';

export const scraperService = {
    scraperRequest,
    updateScraperCredentials,
};

function scraperRequest(method, endpoint, headers = {}) {
    const requestOptions = {
        method,
        headers: {
            endpoint,
            studyId: headers.studyId,
            'migros-email': headers.migrosEmail,
            'migros-password': headers.migrosPassword,
            'coop-email': headers.coopEmail,
            'coop-password': headers.coopPassword,
        },
    };

    return fetch(`${configData.API_URL}/scraper-api`, requestOptions).then(async (res) => {
        try {
            const jsonString = await res.text();
            if (endpoint.includes('scraper-status')) {
                const jsonObject = JSON.parse(jsonString);
                return { status: res.status, data: jsonObject };
            } else return { status: res.status, data: jsonString };
        } catch (e) {
            console.log(e);
            return { status: res.status, data: null };
        }
    });
}

function updateScraperCredentials(migrosEmail, migrosPassword, coopEmail, coopPassword) {
    let user = JSON.parse(localStorage.getItem('user'));

    const requestOptions = {
        method: 'POST',
        headers: {
            ...(user && { Authentication: user.token }),
            endpoint: '/user/update-credentials',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            migrosEmail,
            migrosPassword,
            coopEmail,
            coopPassword,
        }),
    };
    return fetch(`${configData.API_URL}/scraper-api`, requestOptions).then((res) => {
        return res.status;
    });
}
