import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Container, Grid } from '@mui/material';

import SettingsPassword from './SettingsPassword';
import DieticianDashboardPage from './DieticianDashboardPage';

export default function Settings() {
    const { t } = useTranslation();

    return (
        <DieticianDashboardPage
            title={t('title_dietician_dashboard')}
            isDietician={true}
            content={
                <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
                    {/* use <Grid container ></Grid> instead if box should not span whole page width */}
                    <Grid>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'grid',
                                flexDirection: 'column',
                                height: 'auto',
                            }}
                        >
                            <SettingsPassword />
                        </Paper>
                    </Grid>
                </Container>
            }
        />
    );
}
