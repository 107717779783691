//TODO: change these localStorage

const dev = {
    // API_URL: 'http://localhost:5682/brainfood/backend',
    API_URL: 'https://diet-coach.interactions.ics.unisg.ch/brainfood/backend',
    username: 'foodcoach',
    password: 'KaKUCxe4X7+QmpQ9',
};

const prod = {
    // API_URL: 'http://localhost:5682/brainfood/backend',
    API_URL: 'https://api.interactions.ics.unisg.ch/brainfood/backend',
    username: 'foodcoach',
    password: 'KaKUCxe4X7+QmpQ9',
};
export const configData = process.env.NODE_ENV === 'development' ? dev : prod;
