import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { Paper, Divider, Container, Grid, Card, CardContent, Typography } from '@mui/material';

import DieticianDashboardPage from './DieticianDashboardPage';
import { paperStyling } from '../../styles/ComponentStylings';
import { Title } from '../../utils/commonComponents';
import { getData } from '../../services/dietician';
import CircularLoading from '../../components/Loading/CircularLoading';
import { scraperService } from '../../services/scraperService';
import { useState } from 'react';
import i18next from 'i18next';

const localeMap = {
    en: 'en-US',
    de: 'de-DE',
    'de-DE': 'de-DE',
    'en-US': 'en-US',
    'en-GB': 'en-GB',
};

export default function DieticianDashboard() {
    const { t } = useTranslation();
    const { data, error } = useSWR('/dietician/get-brainfood-user-statistics', (url) => getData(url));
    const { data: scraperStatus } = useSWR('/scraper-api/', () =>
        scraperService.scraperRequest('GET', '/scraper-status')
    );

    const [locale] = useState(i18next.language);

    if (error) {
        return (
            <DieticianDashboardPage
                title={t('title_dietician_dashboard')}
                isDietician={true}
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_connecting_server')}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    return (
        <DieticianDashboardPage
            title={t('title_dietician_dashboard')}
            isDietician={true}
            content={
                <>
                    {!data ? (
                        <CircularLoading />
                    ) : (
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Paper sx={paperStyling}>
                                <Title sx={{ flexGrow: 1, pl: 2.5, pb: 2 }} title={t('brainfood_study_overview')} />
                                <Divider />

                                <List sx={{ width: '100%', alignItems: 'center', justify: 'center' }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                        direction="column"
                                    >
                                        <Grid item xs={12}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <SupervisorAccountIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={t('dieticians')} secondary={data.dieticians} />
                                            </ListItem>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                    <Container maxWidth="lg">
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={4}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('participants_with_shopping_data')}
                                                        secondary={data.participants_with_shopping_data}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonOffIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('participants_without_shopping_data')}
                                                        secondary={data.participants_without_shopping_data}
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                    {scraperStatus && (
                                        <Container maxWidth="lg">
                                            <Grid container>
                                                <>
                                                    <Grid item xs={6}>
                                                        {/* Content for the second grid */}
                                                        <ListItem>{t('Scraper Status Migros')}</ListItem>
                                                        <ListItem sx={{ pl: 4 }}>
                                                            {scraperStatus.data ? (
                                                                scraperStatus.data.migros === 'ok' ? (
                                                                    <Avatar sx={{ bgcolor: 'green' }}> </Avatar>
                                                                ) : scraperStatus.data.migros === 'error' ? (
                                                                    <Avatar sx={{ bgcolor: 'red' }}> </Avatar>
                                                                ) : (
                                                                    <ListItemText primary={t('unknown')} />
                                                                )
                                                            ) : (
                                                                <ListItemText primary={t('unknown')} />
                                                            )}
                                                            {scraperStatus.data &&
                                                                scraperStatus.data.lastCheckMigros && (
                                                                    <ListItemText
                                                                        sx={{ pl: 2 }}
                                                                        secondary={new Date(
                                                                            scraperStatus.data.lastCheckMigros
                                                                        ).toLocaleString(localeMap[locale])}
                                                                    />
                                                                )}
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        {/* Content for the first grid */}
                                                        <ListItem>{t('Scraper Status Coop')}</ListItem>
                                                        <ListItem sx={{ pl: 4 }}>
                                                            {scraperStatus.data ? (
                                                                scraperStatus.data.coop === 'ok' ? (
                                                                    <Avatar sx={{ bgcolor: 'green' }}> </Avatar>
                                                                ) : scraperStatus.data.coop === 'error' ? (
                                                                    <Avatar sx={{ bgcolor: 'red' }}> </Avatar>
                                                                ) : (
                                                                    <ListItemText primary={t('unknown')} />
                                                                )
                                                            ) : (
                                                                <ListItemText primary={t('unknown')} />
                                                            )}
                                                            {scraperStatus.data && scraperStatus.data.lastCheckCoop && (
                                                                <ListItemText
                                                                    sx={{ pl: 2 }}
                                                                    secondary={new Date(
                                                                        scraperStatus.data.lastCheckCoop
                                                                    ).toLocaleString(localeMap[locale])}
                                                                />
                                                            )}
                                                        </ListItem>
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </Container>
                                    )}
                                </List>
                            </Paper>
                        </Container>
                    )}
                </>
            }
        />
    );
}
